body {
  margin: 0;
  padding: 0;
  background-color: white;
}

h4 {
  color: red;
  font-family: "Roboto", sans-serif;
}

:root {
  --gray-150: #3d4467;
}

.gray-50 {
  color: #c3c5ce;
}

.gray-90 {
  color: #5e6379;
}

.gray-100 {
  color: #545b79;
}

.default-color {
  color: #545b79;
}

.bg-black-100 {
  background-color: #eeeded;
  border: 0;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

/* Button hover effect */
#back-to-top-btn:hover {
  background-color: #6b8b65; /* Change background color on hover */
}

.transparent-modal-bundle .modal-content {
  background-image: url("./images/mobile/background-black-star.png");
  border: none;
}

.transparent-modal-bundle .modal-body {
  background-color: unset;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: 80% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.transparent-modal-bundle .modal-header,
.transparent-modal-bundle .modal-body,
.transparent-modal-bundle .modal-footer {
  background-color: unset;
}

.transparent-modal .modal-content {
  background-color: unset;
  border: none;
}

.transparent-modal .modal-body {
  background-color: unset;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: 80% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.transparent-modal .modal-header,
.transparent-modal .modal-body,
.transparent-modal .modal-footer {
  background-color: unset;
}

.title {
  width: fit-content;
  max-width: 710px;
  margin: 0 0 30px;
  font-size: 48px;
  color: white;
}
.w-min-content {
  width: min-content;
}

/* navbar start */
.navbar {
  background-image: url("./images/mobile/background-black-star.webp");
}

.navbar .custom-toggler {
  border-color: transparent; /* Change border color to white */
}

.navbar .custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
/* navbar end */

/* start buy-ticket */

.buy-ticket {
  padding-top: 4.5rem;
  background-image: url("./images/soul/background-soul-1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 3rem;
}

.btn-check-ticket {
  background-color: #1c4a3a;
  border-radius: 10px;
  border: 1px solid #1c4a3a;
}

/* end buy-ticket */

/* start home */

.home {
  padding-top: 4.5rem;
  background-image: url("./images/soul/background-soul-1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 3rem;
}

.img-icon-title {
  width: 200%;
  z-index: 1;
  margin-top: -5.2rem;
}

.img-icon-line-up {
  margin-top: -7rem;
  width: 50%;
  z-index: 1;
}

.img-icon-buy-now {
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  width: 60%;
  z-index: 1;
}

.img-icon-buy-now-pay {
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
  z-index: 1;
}

.img-seating-plan {
  margin-top: 1rem;
  width: 90%;
  z-index: 1;
}

.img-icon-1 {
  rotate: 30deg;
}

.img-icon-2 {
  rotate: -10deg;
}

.img-icon-1 {
  animation: rotateImage 10s infinite;
}

.img-icon-2 {
  animation: upAndDown 2s infinite;
}

.img-icon-3 {
  rotate: 180deg;
}

.img-icon-4 {
  rotate: -10deg;
}

.img-icon-3 {
  animation: upAndDown 3s infinite;
}

.img-icon-4 {
  animation: rotateImage 8s infinite;
}

.img-icon-5 {
  margin-top: 7rem;
  width: 100%;
}

.img-icon-6 {
  margin-top: -25rem;
  width: 70%;
}

.img-icon-artis-list {
  margin-top: 1rem;
  width: 90%;
}

.img-icon-bersama {
  margin-top: 1rem;
  width: 300%;
}

.img-icon-artis-number {
  margin-top: 1rem;
  margin-left: -4rem;
  margin-top: 1.2rem;
  width: 25%;
}

.img-icon-artis-nasional {
  margin-top: 1rem;
  width: 170%;
  margin-left: -5.5rem;
}

.img-selesai {
  margin-bottom: -9rem;
  width: 100%;
}

.tab-pils {
  border: 2px solid white;
  padding: 0.4rem;
  border-radius: 100px;
  margin: 1rem;
}

.nav-pills .nav-link {
  background-color: transparent;
  text-align: center;
  color: white;
  font-weight: 600;
  border-radius: 100px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-radius: 100px;
  background-color: rgba(239, 95, 164, 1);
}

.p-discount-ticket {
  background-image: url(./images/mobile/background-button-discount.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
  margin-top: -1.3rem;
  margin-bottom: -0.7rem;
  font-size: 10px;
  font-weight: 400;
  padding-block: 2px;
}

.btn-buy-now-ticket {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(246, 235, 9, 1);
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-weight: 600;
  height: 48px;
  letter-spacing: 0.25px;
  font-size: 0.9rem;
  border: 2px solid white;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform, opacity;
  z-index: 0;
}

.btn-buy-now-ticket:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.p-discount {
  background-image: url(./images/mobile/background-button-discount.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
  color: white;
  margin-top: -1.3rem;
  margin-bottom: -0.8rem;
  font-size: 12px;
  font-weight: 400;
  padding-block: 3px;
}

/* start seating */
.seating {
  padding-top: 4.5rem;
  background-image: url("./images/soul/background-soul-1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 3rem;
}

.control-buttons {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.custom-tabs .nav-link.active {
  background-color: #1c4a3a !important; /* Warna latar belakang tab aktif */
  color: #af904a !important; /* Warna teks tab aktif */
  border-color: #1c4a3a !important; /* Warna border tab aktif */
}

.custom-tabs .nav-link {
  color: white !important;
}

.control-button {
  width: 30px;
  height: 30px;
  border: none;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-size: 24px;
  font-size: 12px;
  color: #000;
  display: flex;
  z-index: 99;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s, box-shadow 0.2s;
}

.control-button:hover {
  background-color: white;
  z-index: 99;
}

.control-button:active {
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* end seating */

/* CSS */
.btn-buy-now {
  align-items: center;
  appearance: none;
  background-color: transparent !important;
  border-radius: 24px;
  border-style: none;
  box-shadow: white;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-weight: 400;
  height: 48px;
  letter-spacing: 0.25px;
  border: 2px solid white;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 5rem;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 90%;
  will-change: transform, opacity;
  z-index: 0;
}

.btn-buy-now:hover {
  background: white;
  color: white;
}

.btn-buy-now:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
  background-color: white;
}

.btn-buy-now:focus {
  outline: none;
  border: 2px solid white;
  background-color: white;
}

.btn-buy-now:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.btn-buy-now:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.btn-buy-now:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.btn-buy-now:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.btn-buy-now:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-container {
  position: relative;
  display: inline-block;
}

.discount-label {
  background-color: #d32f2f;
  color: white;
  padding: 5px 20px;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 0 10px 10px 0; /* Custom border-radius for cut-out effect */
}

.discount-label::before,
.discount-label::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  background-color: #d32f2f;
}

.discount-label::before {
  left: -20px;
  border-radius: 0 50% 50% 0; /* Left rounded cut-out */
}

.discount-label::after {
  right: -20px;
  border-radius: 50% 0 0 50%; /* Right rounded cut-out */
}

.main-button {
  background-color: #ffeb3b;
  color: #1e88e5;
  border: 2px solid #1e88e5;
  border-radius: 50px;
  padding: 15px 40px;
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  position: relative;
  z-index: 0;
  cursor: pointer;
  display: inline-block;
  margin-top: 20px; /* Adjust as needed */
}

.plane {
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  width: 10%; /* Sesuaikan ukuran sesuai kebutuhan */
  animation: moveRightToLeft 10s linear infinite;
}

.ticket-box {
  border-radius: 10px;
}

.img-ticket {
  width: 100%;
}

.img-alert {
  width: 9%;
}

.day-2-active {
  border-radius: 50px;
  border: 1px solid black;
}

.day-2 {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 2.5rem;
  margin-inline: 0.3rem;
  border-radius: 50px;
  background-color: rgba(203, 203, 203, 1);
}

.tx-ticket-active {
  color: white;
  font-weight: 700;
}

.tx-ticket {
  color: rgba(71, 64, 70, 1);
  font-weight: 700;
  opacity: 70%;
}

.discount-ticket-tag {
  font-size: 0.5rem;
  padding-block: 0.2rem;
  font-weight: 700;
  border-radius: 5px;
  text-align: center;
  align-items: center;
}

.discount-ticket-col {
  border-radius: 10px;
  margin-top: -0.5rem;
  height: fit-content;
}

.lines {
  height: 1px;
  align-self: center;
  border: "2px solid white";
  background-color: white;
}

.lines-black {
  height: 1px;
  align-self: center;
  border: "2px solid black";
  margin-inline: 0.6rem;
  background-color: black;
}

.btn-email_ver {
  background-color: #af904a;
  border: 2px solid #af904a;
  z-index: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn-email_ver:hover {
  background-color: #af904a;
  z-index: 0;
  border: 2px solid #af904a;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn-city_ver {
  margin-bottom: 10px;
  background-color: #af904a;
  border: 2px solid #af904a;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn-city_ver:hover {
  background-color: #af904a;
  border: 2px solid #af904a;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.invalid-feedback {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.input-email {
  color: rgb(0, 0, 0);
  border: 2px solid #af904a;
  background-color: rgb(255, 251, 251);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.input-email::placeholder {
  color: #000000;
}

.input-regis {
  margin-bottom: 10px;
  color: white;
  border: 1px solid white;
  background-color: transparent;
  border-radius: 30px;
}

.input-regis::placeholder {
  color: rgb(126, 126, 126);
}

.input-city {
  margin-bottom: 10px;
  color: white;
  border: 1px solid white;
  background-color: transparent;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.input-city::placeholder {
  color: rgb(126, 126, 126);
}

hr {
  border: none;
  border-top: 2px dotted white;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.box-count-down {
  background-color: white;
  margin-right: 10px;
  font-weight: 800;
  padding: 4px;
  color: red;
  border-radius: 5px;
}

.tx-discount-total {
  text-decoration: line-through;
  font-weight: 700;
  color: red;
  font-size: 20px;
}

.tx-total-ticket {
  color: black;
  font-weight: 600;
}

.tx-discount {
  color: rgb(32, 120, 35);
  font-weight: 800;
}

.tx-admin {
  color: white;
  font-weight: 800;
}

.btn-goto-wa {
  position: fixed;
  z-index: 1;
  bottom: 0;
  background-color: transparent;
  border: 0px;
  right: 0;
  background-image: url("./images/soul/wa-btn.png");
  background-size: 100% 100%;
  width: 120px;
  height: 50px;
  margin-bottom: 2rem;
  margin-right: 2rem;
  align-content: end;
}

.btn-goto-check-out {
  position: fixed;
  z-index: 99;
  background-color: white;
  color: black;
  font-size: 1.5em;
  color: #1c4a3a;
  font-weight: 700;
  border-radius: 15px;
  bottom: 0;
  border: 0px;
  right: 0;
  margin-right: 4%;
  background-size: 100% 100%;
  width: 92%;
  height: 50px;
  margin-bottom: 2rem;
  align-content: end;
}

.btn-flash-sale {
  position: fixed;
  z-index: 999;
  top: 0;
  border-radius: 0px;
  background-color: transparent;
  border: 0px;
  right: 0;
  background-color: red;
  width: 100%;
  height: 60px;
  align-content: center;
}

.btn-goto-ig {
  position: fixed;
  z-index: 1;
  bottom: 0;
  background-color: transparent;
  border: 0px;
  right: 0;
  background-image: url("./images/mobile/Instagram_icon.webp");
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
  margin-bottom: 2rem;
  margin-right: 6rem;
  align-content: end;
}

.btn-goto-wa:active {
  background-color: transparent;
}

.btn-goto-wa:hover {
  background-color: transparent;
}

.btn-goto-wa:focus {
  background-color: transparent;
}

.tx-price-total {
  color: #fff001;
  font-weight: 800;
  font-size: 1.1rem;
}

.tx-price-total-cross {
  color: red;
  font-weight: 500;
}

.bg-ticket-tot {
  width: fit-content;
  color: black;
  border-radius: 30px;
  padding-block: 5px;
  background-color: transparent;
  font-weight: 800;
  align-items: center;
  text-align: center;
}

.bg-ticket-txtot {
  margin-bottom: -1px;
  margin-left: -4px;
  color: black;
  font-weight: 800;
  align-items: center;
  text-align: center;
}

.img-icon-va {
  margin-top: 2rem;
  width: 70%;
  z-index: 1;
}

.ticket-box-payment {
  margin-bottom: 1rem;
  text-align: center;
  border: 2px solid #af904a;
  padding: 1rem;
  border-radius: 10px;
  background-color: #89a492;
}

.col-box-payment {
  margin-bottom: 1rem;
  text-align: center;
  border: 2px dashed #af904a;
  padding: 1rem;
  border-radius: 10px;
  background-color: #89a492;
}

.payment-select {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #af904a;
  padding-block: 0.8rem;
  margin-bottom: 0.8rem;
  padding-inline: 1rem;
}

.tab-payment {
  background-size: 100% 100%;
  background-position: top;
}

.btn-copy-va {
  font-weight: 300;
  background-color: rgba(240, 92, 164, 1);
  border: 1px solid rgba(240, 92, 164, 1);
  border-radius: 20px;
}

.img-icon-qris {
  width: 50%;
  z-index: 1;
}

.img-icon-title {
  width: 200%;
  z-index: 1;
  margin-top: -5.2rem;
}

.col-box-variation {
  border: 2px solid #af904a;
  background-color: #89a492;
  border-radius: 10px;
}

.col-box-variation-ticket {
  border: 2px solid #af904a;
  background-color: white;
  border-radius: 10px;
}

.col-box-total-payment {
  background-color: #deca9e;
  padding: 10px;
  border-radius: 10px;
}

.sale-ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0em;
}

.span-sale-ribbon {
  display: block;
  position: absolute;
  width: 120px;
  background: #e74c3c;
  color: #fff;
  font-weight: 500;
  text-align: center;
  transform: rotate(45deg);
  top: 15px;
  text-transform: uppercase;
  left: 10px;
}
/* end home */

/* start not found */

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  font-family: "Arial", sans-serif;
}

.not-found-title {
  font-size: 10rem;
  margin: 0;
  color: #24b0a2;
}

.not-found-text {
  font-size: 1.5rem;
  color: #2d3436;
  margin: 1rem 0;
}

.not-found-button {
  padding: 0.75rem 1.5rem;
  color: #fff;
  background-color: #24b0a2;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: #24b0a2;
}

.not-found-animation {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 200px;
  margin-top: 2rem;
}

.not-found-cloud {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: moveClouds 15s linear infinite;
}

.not-found-cloud:nth-child(2) {
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50%;
  animation-duration: 18s;
}
.guest-star-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@keyframes moveClouds {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

/* end not found */

/* Start media query */

/* Small */
@media (max-width: 768px) {
}

/* medium */

@media (min-width: 768px) {
  .home .img-hero {
    width: 100%;
    margin-top: -2rem;
  }

  .home .img-icon-1 {
    width: 6rem;
    height: 6rem;
    rotate: 30deg;
  }

  .home .img-icon-2 {
    width: 7rem;
    height: 7rem;
  }

  .home .img-icon-3 {
    width: 6rem;
    height: 6rem;
  }

  .home .img-icon-4 {
    width: 7rem;
    height: 7rem;
  }
}

@media (max-width: 992px) {
  .home .img-hero {
    width: 80%;
    margin-top: -1.5rem;
  }

  .home .img-icon-1 {
    margin-left: 1rem;
    width: 3rem;
    height: 3rem;
    rotate: 30deg;
  }

  .home .img-icon-2 {
    width: 4rem;
    height: 4rem;
  }
  .home .img-icon-3 {
    width: 4rem;
    height: 4rem;
    margin-top: -1rem;
  }

  .home .img-icon-4 {
    margin-top: -2rem;
    width: 3rem;
    height: 3rem;
  }
}

/* Large */

@media (min-width: 1198px) {
  .home {
    padding-inline: 20%;
  }

  .header .col-form-box {
    width: 60%;
    height: fit-content;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .home .img-hero {
    width: 40%;
    margin-top: -4rem;
  }

  .home .img-icon-1 {
    text-align: center;
    width: 10rem;
    height: 10rem;
    margin-left: 10rem;
  }

  .home .img-icon-2 {
    width: 10rem;
    height: 10rem;
    margin-right: 10rem;
  }

  .home .img-icon-3 {
    text-align: center;
    width: 10rem;
    height: 10rem;
  }

  .home .img-icon-4 {
    width: 10rem;
    height: 10rem;
  }

  .img-icon-payment-method {
    margin-top: 2rem;
    padding-top: 1rem;
    width: 100%;
    z-index: 1;
  }
}

/* End media query */

/* start animate */

@keyframes upAndDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rotateImage {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: myFirstFont;
  src: url(../webfonts/fa-regular-400.woff2);
}

@keyframes moveRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
